html, body {
	padding: 0px;
	margin: 0px;
	box-sizing: content-box;
}

html, body, #root {
	height: 100%;
	font-family: 'Poppins', sans-serif;
	font-style: normal;
}

a {
    color: unset;
    text-decoration: unset;
}

.MuiGrid-root.full-height {
	height: 100%;
	align-items: center;
	justify-content: center;
}

.MuiGrid-root.full-height > .MuiGrid-root {
	align-items: center;
}

.illustration {
	/* position: relative; */
	display: grid;
	grid-template-rows: 1fr;
	grid-template-columns: 1fr;
	justify-content: center;
	flex-basis: 100%;
	flex-grow: 1;
}

.illustration img,
.illustration svg {
	/* position: absolute; */
	grid-row-start: 1;
	grid-column-start: 1;
	max-width: 90%;
	max-height: 90%;
	justify-self: center;
}

.illustration svg {
	width: unset;
	height: unset;
}

.contact-image {
	display: flex;
	overflow: hidden;
}

.contact-image img {
	max-width: min(300px, 100%);
	aspect-ratio: 0.6315;
	margin: auto;
}

.page-icon {
  transform: scale(8);
  margin: 100px;
}

.error-page {
	text-align: center;
}

.notify-close-button {
	margin-top: 5px;
	cursor: pointer;
}

.page-icon-shadow {
  transform: scale(8);
  margin: 100px;
  position: absolute;
  top: -10px;
  left: calc(50% - 90px);
  opacity: 0.2;
}
